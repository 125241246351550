@font-face {
    font-family: Font1;
    src: url('https://tabin-public.s3.ap-southeast-2.amazonaws.com/restaurant-fonts/Menlo/Menlo-Regular.ttf') format("opentype");
}

@font-face {
    font-family: Font2;
    src: url('https://tabin-public.s3.ap-southeast-2.amazonaws.com/restaurant-fonts/Menlo/Menlo-Regular.ttf') format("opentype");
}

@font-face {
    font-family: Font3;
    src: url('https://tabin-public.s3.ap-southeast-2.amazonaws.com/restaurant-fonts/Menlo/Menlo-Regular.ttf') format("opentype");
}

:root {
    --primary-color: #000000;
    --secondary-color: #000000;
    --background-color: #FEFBF3;
    --font-color: #000000;
}

body {
    font-family: 'Font2', Fallback, sans-serif;
    background-color: var(--background-color);
    color: var(--font-color);
}

.restaurant-list {
    display: flex;
    flex-direction: column;
    padding: 24px;
    max-width: 600px;
    width: 100%;
    margin: auto;
}

.restaurant-list-logo {
    width: 40%;
    margin: 0 auto;
    margin-bottom: 32px;
}

.restaurant-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.restaurant-name {
    font-size: 24px;
}

.button {
    display: flex;
    justify-content: center;
    padding: 16px 24px;
    border-radius: 3px;
    cursor: pointer;
    background-color: var(--primary-color);
    color: #FFFFFF;
    border: 1px solid var(--primary-color);
}

.button.disabled {
    cursor: default;
    background-image: none;
    background-color: hsl(0, 0%, 70%);
}